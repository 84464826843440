@tailwind base;
@tailwind components;
@tailwind utilities;

#__next {
  min-height: 100%;
}

.MuiScopedCssBaseline-root,
.MuiInputBase-root {
  background-color: transparent !important;
}

.MuiBox-root {
  background-color: transparent !important;
}

.MuiBadge-root .MuiBox-root {
  background-color: #333 !important;
}

/* STAKEX CHART */
.dapp-apexcharts-xaxis-label {
  @apply fill-dapp-blue-50;
}

body {
  @apply flex h-full flex-col bg-fixed;
}

body:has(.dapp) {
  @apply bg-dapp-blue-800;
}

body:has(.website) {
  @apply bg-light-200 dark:bg-gradient-radial dark:from-darkblue dark:to-dark;
}
